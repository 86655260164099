import Avatar from "./components/avatar/avatar";
import Link from "./components/link/link";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebook,
	faTwitter,
	faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";

const links = [
	{
		name: "spotify",
		url: "https://open.spotify.com/artist/1DarKkvoT253ewkz64CnPN",
	},
	{
		name: "soundcloud",
		url: "https://soundcloud.com/zachricemusic",
	},
	{
		name: "apple music",
		url: "https://music.apple.com/us/artist/zach-rice/1663686992",
	},
];

function App() {
	return (
		<div className="App">
			<div className="wrapper">
				<Avatar />
				<div className="social">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://twitter.com/zachricemusic"
						aria-label="Twitter"
					>
						<FontAwesomeIcon icon={faTwitter} />
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://facebook.com/zachricemusic"
						aria-label="Facebook"
					>
						<FontAwesomeIcon icon={faFacebook} />
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://instagram.com/zachricemusic"
						aria-label="Instagram"
					>
						<FontAwesomeIcon icon={faSquareInstagram} />
					</a>
				</div>
				<div className="desc">
					<p>Zach Rice is an electronic musician from Richmond, VA</p>
				</div>
				<div className="links">
					{links.map((link, i) => {
						return <Link key={i} name={link.name} url={link.url} />;
					})}
				</div>
			</div>
		</div>
	);
}

export default App;
