import React from "react";
import styles from "./link.css";

function link({ name, url }) {
	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href="https://facebook.com/zachricemusic"
			className="link"
			href={url}
		>
			<div>{name}</div>
		</a>
	);
}

export default link;
